import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAllDepartments(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('cms/admin/blogtags',{ params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

     
    createDepartment(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('cms/admin/blogtag',  userData  )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateblogtag(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put('cms/admin/updateblogtag',  userData  )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    


  },
}
